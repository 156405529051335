/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button.green {
  color: #fff;
  background-color: #04AA6D;
  border-color: #04AA6D;
}

.snackbar-default {
  button {
    color: steelblue;
  ;}
}